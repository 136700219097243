/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it
require('./src/scss/main.scss');
require('bootstrap/dist/css/bootstrap.min.css');

// import Modal from "react-modal"
// Modal.setAppElement("#root")
