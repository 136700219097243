exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-about-company-js": () => import("./../../../src/pages/about/about-company.js" /* webpackChunkName: "component---src-pages-about-about-company-js" */),
  "component---src-pages-about-board-members-js": () => import("./../../../src/pages/about/board-members.js" /* webpackChunkName: "component---src-pages-about-board-members-js" */),
  "component---src-pages-about-corporate-statement-js": () => import("./../../../src/pages/about/corporate-statement.js" /* webpackChunkName: "component---src-pages-about-corporate-statement-js" */),
  "component---src-pages-about-management-members-js": () => import("./../../../src/pages/about/management-members.js" /* webpackChunkName: "component---src-pages-about-management-members-js" */),
  "component---src-pages-about-supply-chain-js": () => import("./../../../src/pages/about/supply-chain.js" /* webpackChunkName: "component---src-pages-about-supply-chain-js" */),
  "component---src-pages-about-vision-mission-js": () => import("./../../../src/pages/about/vision-mission.js" /* webpackChunkName: "component---src-pages-about-vision-mission-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-privacypolicy-index-js": () => import("./../../../src/pages/privacypolicy/index.js" /* webpackChunkName: "component---src-pages-privacypolicy-index-js" */),
  "component---src-pages-products-bikes-honda-cd-70-downpayment-calculator-js": () => import("./../../../src/pages/products/bikes/honda-cd70-downpayment-calculator.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-downpayment-calculator-js" */),
  "component---src-pages-products-bikes-honda-cd-70-downpayment-js": () => import("./../../../src/pages/products/bikes/honda-cd70-downpayment.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-downpayment-js" */),
  "component---src-pages-products-bikes-honda-cd-70-downpayment-ro-js": () => import("./../../../src/pages/products/bikes/honda-cd70-downpayment-ro.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-downpayment-ro-js" */),
  "component---src-pages-products-bikes-honda-cd-70-downpayment-ur-js": () => import("./../../../src/pages/products/bikes/honda-cd70-downpayment-ur.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-downpayment-ur-js" */),
  "component---src-pages-products-bikes-honda-cd-70-installment-calculator-js": () => import("./../../../src/pages/products/bikes/honda-cd70-installment-calculator.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-installment-calculator-js" */),
  "component---src-pages-products-bikes-honda-cd-70-installment-js": () => import("./../../../src/pages/products/bikes/honda-cd70-installment.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-installment-js" */),
  "component---src-pages-products-bikes-honda-cd-70-installment-ro-js": () => import("./../../../src/pages/products/bikes/honda-cd70-installment-ro.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-installment-ro-js" */),
  "component---src-pages-products-bikes-honda-cd-70-installment-ur-js": () => import("./../../../src/pages/products/bikes/honda-cd70-installment-ur.js" /* webpackChunkName: "component---src-pages-products-bikes-honda-cd-70-installment-ur-js" */),
  "component---src-pages-products-motorbikes-js": () => import("./../../../src/pages/products/motorbikes.js" /* webpackChunkName: "component---src-pages-products-motorbikes-js" */),
  "component---src-pages-seller-index-js": () => import("./../../../src/pages/seller/index.js" /* webpackChunkName: "component---src-pages-seller-index-js" */),
  "component---src-pages-stores-index-js": () => import("./../../../src/pages/stores/index.js" /* webpackChunkName: "component---src-pages-stores-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-termsconditions-index-js": () => import("./../../../src/pages/termsconditions/index.js" /* webpackChunkName: "component---src-pages-termsconditions-index-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

